import React, { useState } from "react"
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import AnimatedTitle from "../compassi/animateTitle"
import { Box, Grid, Heading, Flex, Text, Image } from "theme-ui"
import ArrowLeft from "../../images/arrow-left.inline.svg"
import ArrowRight from "../../images/arrow-right.inline.svg"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { MagicLink } from "../../utils/magicLink"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])

const PodcastCollection = ({ block }) => {
  const { podcasts } = block

  console.log("podcasts", podcasts)

  return (
    podcasts &&
    podcasts.length > 0 && (
      <Box sx={{ p: [3, 4, 7], pt: [0, 0, 0, 0], backgroundColor: "compassi" }}>
        <Grid columns={[1, 1, 2]}>
          {podcasts.map((podcast, index) => (
            <PodcastComponent podcast={podcast} index={index} />
          ))}
        </Grid>
      </Box>
    )
  )
}

const PodcastComponent = ({ podcast, index }) => {
  const { description } = podcast
  return (
    <Box
      sx={{
        // width: ["235px", "315px"],
        p: {
          m: [0],
          color: "light",
        },
      }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  )
}

export default PodcastCollection
