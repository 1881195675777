import React, { useEffect, useRef, useLayoutEffect, useState } from "react"
import { Box, Heading, Image } from "theme-ui"
import gsap from "gsap"

const Stories = ({ block }) => {
  const { title1, title2, title3, gallery } = block
  const titles = [title1, title2, title3]
  const [randomArray, setRandomArray] = useState([])

  useEffect(() => {
    setRandomArray(gallery)
  }, [])

  return (
    <Box sx={{ px: [3, 4, 7], pb: [3, 4, 7], display:["none","none","block"],zIndex:999 }}>
      {titles.map((title, index) => (
        <TitleComponent
          title={title}
          index={index}
          image={randomArray[index]}
        />
      ))}
    </Box>
  )
}

const TitleComponent = ({ title, index, image }) => {
  const isBrowser = typeof window !== "undefined"
  const items = isBrowser && document.querySelectorAll(".item")

  const endX = useRef(isBrowser && window.innerWidth / 2)
  const endY = useRef( isBrowser && window.innerHeight / 2)
  useLayoutEffect(() => {
    const el = document.querySelector(`.image_item${index}`)
    const text = el.querySelector("span")
    const image = el.querySelector("img")

    text.addEventListener("mouseenter", e => {
      text.style.color = "#A81815"
      gsap.to(image, { autoAlpha: 1 })
    })

    text.addEventListener("mouseleave", e => {
      text.style.color = "#222222"
      gsap.to(image, { autoAlpha: 0 })
    })

    text.addEventListener("mousemove", e => {
      endX.current = e.pageX
      endY.current = e.pageY

      gsap.set(image, {
        left: (index % 2 ? endX.current : endX.current) + "px",
      })
    })
  })

  return (
    <Box
      className={`image_item  image_item${index}`}
      sx={{
        textAlign: index % 2 ? "right" : "left",
        borderBottom: "1px solid",
        borderColor: "dark",
        pt: [6, 8],
        display: "flex",
        justifyContent: index % 2 ? "flex-end" : "flex-start",
        position: "relative",
      }}
    >
      <Heading
        as="span"
        sx={{
          fontFamily: "compassi",
          color: "dark",
          fontSize: [8, "100px"],
          position: "relative",
          fontStyle:index === 1 && "italic"
        }}
      >
        {title}
      </Heading>
      {image && (
        <Image
          sx={{
            position: "absolute",
            width: "300px",
            height: "400px",
            objectFit: "cover",
            transform: "translateX(-50%) translateY(-50%)",
            top: "50%",
            left: "50%",
            zIndex: -10,
            opacity: 0,
            visibily: "hidden",
            pointerEvents: "none",
            "mix-blend-mode": "difference",
            "&:before": {
              "mix-blend-mode": "difference",
            },
            "&after": {
              "mix-blend-mode": "difference",
            },
          }}
          src={image.url}
          image={image.alt}
        />
      )}
    </Box>
  )
}

export default Stories
