// AnimatedTitle.js

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useAnimation, motion } from "framer-motion"
import Marquee from "react-fast-marquee"

const Title = styled.h2`
  font-size: 120px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 0.7;
  color: #A81815;
  font-family: "NeueHaasGroteskDisp Pro", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
`

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`

const Character = styled(motion.span)`
  display: inline-block;
`

const AnimatedTitle = ({ isHover, text }) => {
  const ctrls = useAnimation()

  useEffect(() => {
    console.log("AnimatedTitle", isHover)
    if (isHover) {
      ctrls.start("visible")
    }
    if (!isHover) {
      ctrls.start("hidden")
    }
  }, [ctrls, isHover])

  const wordAnimation = {
    hidden: {},
    visible: {},
  }

  const characterAnimation = {
    hidden: {
      y: `200px`,
      rotate: "5deg",
      rotate: "90deg",
      scale: 1.00001,
      transition: {
        duration: 1,
        ease: [0.2, 0.3, 0.4, 0.5],
      },
    },
    visible: {
      y: `0em`,
      rotate: "0deg",
      transition: {
        duration: .6,
        ease: [0.2, 0.3, 0.4, 0.5],
      },
    },
  }

  return (
    <Marquee gradient={false} speed={60}>
      <Title aria-label={text} role="heading">
        {text.split(" ").map((word, index) => {
          return (
            <Word
              aria-hidden="true"
              key={index}
              initial="hidden"
              animate={ctrls}
              variants={wordAnimation}
              transition={{
                delayChildren: index * 0.05,
                staggerChildren: 0.01,
              }}
            >
              {word.split("").map((character, index) => {
                return (
                  <Character
                    aria-hidden="true"
                    key={index}
                    variants={characterAnimation}
                  >
                    {character}
                  </Character>
                )
              })}
            </Word>
          )
        })}
      </Title>
    </Marquee>
  )
}

export default AnimatedTitle
