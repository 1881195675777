import React from "react"
import {
  Box,
  Text,
  Container,
  Heading,
  Flex,
  Image,
} from "@theme-ui/components"
import Marquee from "react-fast-marquee"
import { MagicLink } from "../../utils/magicLink"

const CompassiMarquee = ({ block }) => {
  const { text, image, link, darkBackground } = block

  const IsItLink = link ? MagicLink : Box
  return (
    <Box
      sx={{
        backgroundColor: darkBackground ? "compassi" : "light",
        py: [4, 6],
        borderTop:"1px solid",
        borderBottom:"1px solid",
        borderColor: darkBackground ? "light" : "dark",
        position: "relative",
        zIndex: 3,

      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Marquee gradient={false} speed={80}>
          <IsItLink
            item={link}
            sx={{
              display: "flex",
              alignItems:"center"
            }}
          >
            <Text
              sx={{
                fontSize: [8, 11],
                fontFamily: "compassi",
                color: darkBackground ? "light" : "dark",
              }}
            >
              {text}
            </Text>
            <Image sx={{ px: [2] }} src={image.url} alt={image.alt} />
            <Text
              sx={{
                fontSize: [8, 11],
                fontFamily: "compassi",
                color: darkBackground ? "light" : "dark",
              }}
            >
              {text}
            </Text>
            <Image sx={{ px: [2] }} src={image.url} alt={image.alt} />
            <Text
              sx={{
                fontSize: [8, 11],
                fontFamily: "compassi",
                color: darkBackground ? "light" : "dark",
              }}
            >
              {text}
            </Text>
            <Image sx={{ px: [2] }} src={image.url} alt={image.alt} />
            <Text
              sx={{
                fontSize: [8, 11],
                fontFamily: "compassi",
                color: darkBackground ? "light" : "dark",
              }}
            >
              {text}
            </Text>
            <Image sx={{ px: [2] }} src={image.url} alt={image.alt} />
          </IsItLink>
        </Marquee>
      </Box>
    </Box>
  )
}

export default CompassiMarquee
