import React from "react"
import { Box } from "theme-ui"
import "../../styles/footerBB2023.css"

const BergamoBresciaFooter = ({ block }) => {
  const {} = block
  return (
    <Box
      sx={{
        ".ePzqYe": {
          display: "block",
          textAlign: "left",
          letterSpacing: "0px",
          color: "rgb(60, 60, 59)",
          opacity: 1,
          font: "bold 10px / 14px Nunito !important",
          marginBottom: "20px !important",
          marginTop: "0px !important",
        },
      }}
    >
      <footer className="ant-layout-footer styled__StyledFooter-sc-tifvx4-0 gWUwiw">
        <div className="responsive-block__ResponsiveBlock-sc-l2nyau-0 kXeGbn">
          <br />
          <br />
          <br />
        </div>
        <div
          className="ant-row"
          style={{ marginLeft: "-10px", marginRight: "-10px", rowGap: "60px" }}
        >
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-4 ant-col-xl-5 ant-col-xxl-6"
          >
            <div
              className="ant-row ant-row-center ant-row-bottom styled__LogoRow-sc-tifvx4-6 gDTsZX"
              style={{
                height: "100%",
                rowGap: "60px",
                marginLeft: "-30px",
                marginRight: "-30px",
              }}
            >
              <div
                style={{ paddingLeft: "30px", paddingRight: "30px" }}
                className="ant-col ant-col-xs-20 ant-col-sm-10 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
              >
                <div className="styled__LogoWrapper-sc-tifvx4-4 hxmmmp">
                  <img
                    src="https://bergamobrescia2023.it/images/logo.svg"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-16 ant-col-lg-20 ant-col-xl-19 ant-col-xxl-17"
          >
            <div
              className="ant-row"
              style={{
                rowGap: "60px",
                marginLeft: "-30px",
                marginRight: "-30px",
              }}
            >
              <div
                style={{ paddingLeft: "30px", paddingRight: "30px" }}
                className="ant-col ant-col-24"
              >
                <div className="ant-row ant-row-center">
                  <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24">
                    <div className="responsive-block__ResponsiveBlock-sc-l2nyau-0 gOniBM">
                      <div className="ant-row ant-row-center">
                        <div className="ant-col">
                          <div
                            className="ant-space ant-space-horizontal ant-space-align-center"
                            style={{ gap: "24px" }}
                          >
                            <div className="ant-space-item" style={{}}>
                              <a
                                href="https://www.comune.bergamo.it/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2762%27%20height=%2764%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="comune"
                                    src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=128&q=75"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=64&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=128&q=75 2x"
                                  />
                                </span>
                              </a>
                            </div>
                            <div className="ant-space-item">
                              <a
                                href="https://www.comune.brescia.it/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2739%27%20height=%2764%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="comune brescia"
                                    src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=96&q=75"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=48&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=96&q=75 2x"
                                  />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal"
                            role="separator"
                          />
                        </div>
                      </div>
                      <div className="ant-row" style={{ rowGap: "20px" }}>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <span
                                className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzqYe"
                                mb={20}
                              >
                                INSTITUTIONAL PARTNERS
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row" style={{ rowGap: "40px" }}>
                            <div style={{}} className="ant-col ant-col-24">
                              <div
                                className="ant-row"
                                style={{ rowGap: "40px" }}
                              >
                                <div style={{}} className="ant-col ant-col-24">
                                  <div className="ant-row ant-row-center">
                                    <div
                                      style={{ marginRight: "20px" }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.beniculturali.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2799%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="culture"
                                            src="https://bergamobrescia2023.it/images/culture_ministry.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/culture_ministry.svg 1x, https://bergamobrescia2023.it/images/culture_ministry.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                    <div className="ant-col">
                                      <a
                                        href="https://www.regione.lombardia.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27108%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="lombardia"
                                            src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=256&q=75"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=128&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=256&q=75 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <div className="ant-row ant-row-center">
                                    <div
                                      style={{ marginRight: "50px" }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.fondazionecariplo.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2788%27%20height=%2741%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="fondazione"
                                            src="https://bergamobrescia2023.it/images/fondazione_cariplo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/fondazione_cariplo.svg 1x, https://bergamobrescia2023.it/images/fondazione_cariplo.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                    <div
                                      style={{ marginRight: "50px" }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.provincia.bergamo.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2741%27%20height=%2764%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="comune"
                                            src="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg 1x, https://bergamobrescia2023.it/images/Provincia_BG_logo.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                    <div className="ant-col">
                                      <a
                                        href="https://www.provincia.brescia.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2744%27%20height=%2764%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="comune"
                                            src="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg 1x, https://bergamobrescia2023.it/images/Provincia_BS_logo.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <div className="ant-row ant-row-center">
                                    <div
                                      style={{ marginRight: "20px" }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.fondazionebergamo.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27132%27%20height=%2738%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="fondazione"
                                            src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=384&q=75"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=256&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=384&q=75 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                    <div className="ant-col">
                                      <a
                                        href="https://www.fondazionebresciana.org/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2788%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="fondazione comune"
                                            src="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg 1x, https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal"
                            role="separator"
                          />
                        </div>
                      </div>
                      <div className="ant-row" style={{ rowGap: "20px" }}>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <span
                                className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzrsu"
                                mt={20}
                              >
                                MAIN PARTNERS
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{}} className="ant-col ant-col-24">
                          <div
                            className="ant-row ant-row-no-wrap ant-row-center ant-row-middle"
                            style={{
                              marginLeft: "-10px",
                              marginRight: "-10px",
                              rowGap: "20px",
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              className="ant-col"
                            >
                              <a
                                href="https://group.intesasanpaolo.com/it/sezione-editoriale/Cultura-e-tutela-patrimonio-artistico-italiano?utm_campaign=search_istituzionale&utm_source=google&utm_medium=SEM_keyword_CPC&utm_content=SEM&utm_term=google_SEM_keyword_CPC_search_istituzionale_cultura"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27214%27%20height=%2724%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="intensa san paolo"
                                    src="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg 1x, https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                            <div
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              className="ant-col"
                            >
                              <a
                                href="https://www.a2aenergia.eu/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2790%27%20height=%2732%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="a2a"
                                    src="https://bergamobrescia2023.it/images/A2A_logo.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/A2A_logo.svg 1x, https://bergamobrescia2023.it/images/A2A_logo.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal"
                            role="separator"
                          />
                        </div>
                      </div>
                      <div className="ant-row" style={{ rowGap: "20px" }}>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <span className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 jctwjk">
                                SYSTEM PARTNERS
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <a
                                href="https://www.brembo.com/it/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2796%27%20height=%2726%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="brembo"
                                    src="https://bergamobrescia2023.it/images/Brembo_logo.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/Brembo_logo.svg 1x, https://bergamobrescia2023.it/images/Brembo_logo.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal"
                            role="separator"
                          />
                        </div>
                      </div>
                      <div className="ant-row" style={{ rowGap: "20px" }}>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <span className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 jctwjk">
                                PARTNER DI AREA
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div
                              style={{ marginRight: "20px" }}
                              className="ant-col"
                            >
                              <a
                                href="https://www.fsitaliane.it"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2794%27%20height=%2732%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="ferrove"
                                    src="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg 1x, https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                            <div className="ant-col">
                              <a
                                href="https://www.milanbergamoairport.it/en/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27110%27%20height=%2732%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="Milan airport"
                                    src="https://bergamobrescia2023.it/images/BGY_logo.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/BGY_logo.svg 1x, https://bergamobrescia2023.it/images/BGY_logo.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal"
                            role="separator"
                          />
                        </div>
                      </div>
                      <div className="ant-row" style={{ rowGap: "20px" }}>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <span className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 jctwjk">
                                MEDIA PARTNER
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{}} className="ant-col ant-col-24">
                          <div className="ant-row ant-row-center">
                            <div className="ant-col">
                              <a
                                href="https://www.gruppo24ore.ilsole24ore.com/it-it/"
                                target="_blank"
                              >
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      boxSizing: "border-box",
                                      display: "block",
                                      width: "initial",
                                      height: "initial",
                                      background: "none",
                                      opacity: 1,
                                      border: 0,
                                      margin: 0,
                                      padding: 0,
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                      }}
                                      alt=""
                                      aria-hidden="true"
                                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2794%27%20height=%2732%27/%3e"
                                    />
                                  </span>
                                  <img
                                    alt="ferrove"
                                    src="https://bergamobrescia2023.it/images/gruppo-24.svg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      boxSizing: "border-box",
                                      padding: 0,
                                      border: "none",
                                      margin: "auto",
                                      display: "block",
                                      width: 0,
                                      height: 0,
                                      minWidth: "100%",
                                      maxWidth: "100%",
                                      minHeight: "100%",
                                      maxHeight: "100%",
                                    }}
                                    srcSet="https://bergamobrescia2023.it/images/gruppo-24.svg 1x, https://bergamobrescia2023.it/images/gruppo-24.svg 2x"
                                  />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="responsive-block__ResponsiveBlock-sc-l2nyau-0 dWoyUj">
                      <div className="ant-row">
                        <div className="ant-col ant-col-24">
                          <div className="ant-row">
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-4 ant-col-xl-3 ant-col-xxl-3">
                              <div
                                className="ant-row ant-row-no-wrap ant-row-start styled__StyledFirstRow-sc-tifvx4-1 kxMoCU"
                                style={{
                                  marginLeft: "-20px",
                                  marginRight: "-20px",
                                  rowGap: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                  className="ant-col"
                                >
                                  <a
                                    href="https://www.comune.bergamo.it/"
                                    target="_blank"
                                  >
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt=""
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2762%27%20height=%2764%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="comune"
                                        src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=128&q=75"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          maxWidth: "100%",
                                          minHeight: "100%",
                                          maxHeight: "100%",
                                        }}
                                        srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=64&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&w=128&q=75 2x"
                                      />
                                      <noscript>
                                        &lt;img alt="comune"
                                        srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&amp;amp;w=64&amp;amp;q=75
                                        1x,
                                        https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&amp;amp;w=128&amp;amp;q=75
                                        2x"
                                        src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flogo_comune.png&amp;amp;w=128&amp;amp;q=75"
                                        decoding="async" data-nimg="intrinsic"
                                        style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                        loading="lazy"/&gt;
                                      </noscript>
                                    </span>
                                  </a>
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                  className="ant-col"
                                >
                                  <a
                                    href="https://www.comune.brescia.it/"
                                    target="_blank"
                                  >
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt=""
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2739%27%20height=%2764%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="comune brescia"
                                        src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=96&q=75"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          maxWidth: "100%",
                                          minHeight: "100%",
                                          maxHeight: "100%",
                                        }}
                                        srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=48&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&w=96&q=75 2x"
                                      />
                                      <noscript>
                                        &lt;img alt="comune brescia"
                                        srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&amp;amp;w=48&amp;amp;q=75
                                        1x,
                                        https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&amp;amp;w=96&amp;amp;q=75
                                        2x"
                                        src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FLogo_comune_brescia.png&amp;amp;w=96&amp;amp;q=75"
                                        decoding="async" data-nimg="intrinsic"
                                        style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                        loading="lazy"/&gt;
                                      </noscript>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              show="[object Object]"
                              style={{ height: "100%" }}
                              className="ant-col responsive-block__ResponsiveBlock-sc-l2nyau-0 bgFbKI ant-col-lg-1 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div
                                className="ant-divider ant-divider-vertical"
                                style={{ minHeight: "94px" }}
                                role="separator"
                              />
                            </div>
                            <div
                              show="[object Object]"
                              style={{ height: "100%" }}
                              className="ant-col responsive-block__ResponsiveBlock-sc-l2nyau-0 fiSqnE ant-col-md-24 ant-col-lg-24 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div className="ant-row">
                                <div className="ant-col ant-col-8">
                                  <div
                                    className="ant-divider ant-divider-horizontal"
                                    role="separator"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              show="[object Object]"
                              style={{ height: "100%" }}
                              className="ant-col responsive-block__ResponsiveBlock-sc-l2nyau-0 fiSqnE ant-col-lg-24 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div
                                className="ant-divider ant-divider-horizontal"
                                role="separator"
                              />
                            </div>
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-19 ant-col-xl-20 ant-col-xxl-20">
                              <div
                                className="ant-row"
                                style={{
                                  marginLeft: "-5px",
                                  marginRight: "-5px",
                                  rowGap: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                  className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-17 ant-col-lg-9 ant-col-xl-9 ant-col-xxl-10"
                                >
                                  <div
                                    className="ant-row"
                                    style={{ rowGap: "0px" }}
                                  >
                                    <div className="ant-col ant-col-24">
                                      <span
                                        className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzqYe"
                                        mb={20}
                                      >
                                        INSTITUTIONAL PARTNERS
                                      </span>
                                    </div>
                                    <div className="ant-col ant-col-24">
                                      <div
                                        className="ant-row ant-row-no-wrap ant-row-bottom"
                                        style={{
                                          marginLeft: "-26px",
                                          marginRight: "-26px",
                                          rowGap: "0px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            paddingLeft: "26px",
                                            paddingRight: "26px",
                                          }}
                                          className="ant-col"
                                        >
                                          <a
                                            href="https://www.beniculturali.it/"
                                            target="_blank"
                                          >
                                            <span
                                              style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  boxSizing: "border-box",
                                                  display: "block",
                                                  width: "initial",
                                                  height: "initial",
                                                  background: "none",
                                                  opacity: 1,
                                                  border: 0,
                                                  margin: 0,
                                                  padding: 0,
                                                  maxWidth: "100%",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                  alt=""
                                                  aria-hidden="true"
                                                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2799%27%20height=%2732%27/%3e"
                                                />
                                              </span>
                                              <img
                                                alt="culture"
                                                src="https://bergamobrescia2023.it/images/culture_ministry.svg"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  bottom: 0,
                                                  right: 0,
                                                  boxSizing: "border-box",
                                                  padding: 0,
                                                  border: "none",
                                                  margin: "auto",
                                                  display: "block",
                                                  width: 0,
                                                  height: 0,
                                                  minWidth: "100%",
                                                  maxWidth: "100%",
                                                  minHeight: "100%",
                                                  maxHeight: "100%",
                                                }}
                                                srcSet="https://bergamobrescia2023.it/images/culture_ministry.svg 1x, https://bergamobrescia2023.it/images/culture_ministry.svg 2x"
                                              />
                                              <noscript>
                                                &lt;img alt="culture"
                                                srcSet="https://bergamobrescia2023.it/images/culture_ministry.svg
                                                1x,
                                                https://bergamobrescia2023.it/images/culture_ministry.svg
                                                2x"
                                                src="https://bergamobrescia2023.it/images/culture_ministry.svg"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                                loading="lazy"/&gt;
                                              </noscript>
                                            </span>
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            paddingLeft: "26px",
                                            paddingRight: "26px",
                                          }}
                                          className="ant-col"
                                        >
                                          <a
                                            href="https://www.regione.lombardia.it/"
                                            target="_blank"
                                          >
                                            <span
                                              style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  boxSizing: "border-box",
                                                  display: "block",
                                                  width: "initial",
                                                  height: "initial",
                                                  background: "none",
                                                  opacity: 1,
                                                  border: 0,
                                                  margin: 0,
                                                  padding: 0,
                                                  maxWidth: "100%",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                  alt=""
                                                  aria-hidden="true"
                                                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27108%27%20height=%2732%27/%3e"
                                                />
                                              </span>
                                              <img
                                                alt="lombardia"
                                                src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=256&q=75"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  bottom: 0,
                                                  right: 0,
                                                  boxSizing: "border-box",
                                                  padding: 0,
                                                  border: "none",
                                                  margin: "auto",
                                                  display: "block",
                                                  width: 0,
                                                  height: 0,
                                                  minWidth: "100%",
                                                  maxWidth: "100%",
                                                  minHeight: "100%",
                                                  maxHeight: "100%",
                                                }}
                                                srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=128&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&w=256&q=75 2x"
                                              />
                                              <noscript>
                                                &lt;img alt="lombardia"
                                                srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&amp;amp;w=128&amp;amp;q=75
                                                1x,
                                                https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&amp;amp;w=256&amp;amp;q=75
                                                2x"
                                                src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2Flombardia.png&amp;amp;w=256&amp;amp;q=75"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                                loading="lazy"/&gt;
                                              </noscript>
                                            </span>
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            paddingLeft: "26px",
                                            paddingRight: "26px",
                                          }}
                                          className="ant-col"
                                        >
                                          <a
                                            href="https://www.fondazionecariplo.it/"
                                            target="_blank"
                                          >
                                            <span
                                              style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  boxSizing: "border-box",
                                                  display: "block",
                                                  width: "initial",
                                                  height: "initial",
                                                  background: "none",
                                                  opacity: 1,
                                                  border: 0,
                                                  margin: 0,
                                                  padding: 0,
                                                  maxWidth: "100%",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                  alt=""
                                                  aria-hidden="true"
                                                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2788%27%20height=%2741%27/%3e"
                                                />
                                              </span>
                                              <img
                                                alt="fondazione"
                                                src="../..https://bergamobrescia2023.it/images/footerhttps://bergamobrescia2023.it/images/fondazione_cariplo.svg"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  bottom: 0,
                                                  right: 0,
                                                  boxSizing: "border-box",
                                                  padding: 0,
                                                  border: "none",
                                                  margin: "auto",
                                                  display: "block",
                                                  width: 0,
                                                  height: 0,
                                                  minWidth: "100%",
                                                  maxWidth: "100%",
                                                  minHeight: "100%",
                                                  maxHeight: "100%",
                                                }}
                                                srcSet="https://bergamobrescia2023.it/images/fondazione_cariplo.svg 1x, https://bergamobrescia2023.it/images/fondazione_cariplo.svg 2x"
                                              />
                                              <noscript>
                                                &lt;img alt="fondazione"
                                                srcSet="https://bergamobrescia2023.it/images/fondazione_cariplo.svg
                                                1x,
                                                https://bergamobrescia2023.it/images/fondazione_cariplo.svg
                                                2x"
                                                src="https://bergamobrescia2023.it/images/fondazione_cariplo.svg"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                                loading="lazy"/&gt;
                                              </noscript>
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                  className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-7 ant-col-lg-4 ant-col-xl-4 ant-col-xxl-4"
                                >
                                  <div
                                    className="ant-row ant-row-no-wrap"
                                    style={{
                                      marginLeft: "-26px",
                                      marginRight: "-26px",
                                      rowGap: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "26px",
                                        paddingRight: "26px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.provincia.bergamo.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2741%27%20height=%2764%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="comune"
                                            src="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg 1x, https://bergamobrescia2023.it/images/Provincia_BG_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="comune"
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/Provincia_BG_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/Provincia_BG_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "26px",
                                        paddingRight: "26px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.provincia.brescia.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2744%27%20height=%2764%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="comune"
                                            src="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg 1x, https://bergamobrescia2023.it/images/Provincia_BS_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="comune"
                                            srcSet="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/Provincia_BS_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/Provincia_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    display: "flex",
                                  }}
                                  className="ant-col ant-col-xs-24 ant-col-md-24 ant-col-lg-10 ant-col-xl-10 ant-col-xxl-9"
                                >
                                  <div
                                    className="ant-row ant-row-bottom"
                                    style={{
                                      marginLeft: "-13px",
                                      marginRight: "-13px",
                                      rowGap: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "13px",
                                        paddingRight: "13px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.fondazionebergamo.it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27132%27%20height=%2738%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="fondazione"
                                            src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=384&q=75"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=256&q=75 1x, https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&w=384&q=75 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="fondazione"
                                            srcSet="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&amp;amp;w=256&amp;amp;q=75
                                            1x,
                                            https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&amp;amp;w=384&amp;amp;q=75
                                            2x"
                                            src="https://bergamobrescia2023.it/_next/image/?url=%2Fimages%2FFondazione_logo.png&amp;amp;w=384&amp;amp;q=75"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "13px",
                                        paddingRight: "13px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.fondazionebresciana.org/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2788%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="fondazione comune"
                                            src="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg 1x, https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="fondazione comune"
                                            srcSet="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/Fondazione_BS_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ant-col ant-col-24">
                          <div
                            className="ant-divider ant-divider-horizontal styled__DividerWithMarginForMd-sc-1ih4wg3-4 ijBXvv"
                            role="separator"
                          />
                        </div>
                        <div className="ant-col ant-col-24">
                          <div className="ant-row">
                            <div
                              style={{
                                maxWidth: "364px",
                                paddingRight: "20px",
                              }}
                              className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-10 ant-col-xl-9 ant-col-xxl-8"
                            >
                              <div
                                className="ant-row"
                                style={{ rowGap: "23px" }}
                              >
                                <div style={{}} className="ant-col ant-col-24">
                                  <span
                                    className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzrsu"
                                    mt={20}
                                  >
                                    MAIN PARTNERS
                                  </span>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <div className="ant-row ant-row-middle styled__RowForIntensa-sc-1ih4wg3-2 AnMzL">
                                    <div className="ant-col">
                                      <a
                                        href="https://group.intesasanpaolo.com/it/sezione-editoriale/Cultura-e-tutela-patrimonio-artistico-italiano?utm_campaign=search_istituzionale&utm_source=google&utm_medium=SEM_keyword_CPC&utm_content=SEM&utm_term=google_SEM_keyword_CPC_search_istituzionale_cultura"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27214%27%20height=%2724%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="intensa san paolo"
                                            src="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg 1x, https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="intensa san paolo"
                                            srcSet="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/Intesa_san_paolo_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                    <div className="ant-col styled__CollForA2A-sc-1ih4wg3-3 hhfJpN">
                                      <a
                                        href="https://www.a2aenergia.eu/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2776%27%20height=%2726%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="a2a"
                                            src="https://bergamobrescia2023.it/images/A2A_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/A2A_logo.svg 1x, https://bergamobrescia2023.it/images/A2A_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="a2a"
                                            srcSet="https://bergamobrescia2023.it/images/A2A_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/A2A_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/A2A_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ height: "100%" }}
                              className="ant-col ant-col-md-0 ant-col-lg-1 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div
                                className="ant-divider ant-divider-vertical"
                                style={{ minHeight: "94px" }}
                                role="separator"
                              />
                            </div>
                            <div
                              show="[object Object]"
                              style={{ height: "100%" }}
                              className="ant-col responsive-block__ResponsiveBlock-sc-l2nyau-0 fiSqnE ant-col-md-24 ant-col-lg-24 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div className="ant-row">
                                <div className="ant-col ant-col-24">
                                  <div
                                    className="ant-divider ant-divider-horizontal"
                                    style={{ margin: 0, marginTop: "20px" }}
                                    role="separator"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ant-col ant-col-md-5 ant-col-lg-4 ant-col-xl-3 ant-col-xxl-3">
                              <div
                                className="ant-row"
                                style={{ rowGap: "23px" }}
                              >
                                <div style={{}} className="ant-col ant-col-24">
                                  <span
                                    className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzrsu"
                                    mt={20}
                                  >
                                    SYSTEM PARTNERS
                                  </span>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <a
                                    href="https://www.brembo.com/it/"
                                    target="_blank"
                                  >
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt=""
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2796%27%20height=%2726%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="brembo"
                                        src="https://bergamobrescia2023.it/images/Brembo_logo.svg"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          maxWidth: "100%",
                                          minHeight: "100%",
                                          maxHeight: "100%",
                                        }}
                                        srcSet="https://bergamobrescia2023.it/images/Brembo_logo.svg 1x, https://bergamobrescia2023.it/images/Brembo_logo.svg 2x"
                                      />
                                      <noscript>
                                        &lt;img alt="brembo"
                                        srcSet="https://bergamobrescia2023.it/images/Brembo_logo.svg
                                        1x,
                                        https://bergamobrescia2023.it/images/Brembo_logo.svg
                                        2x"
                                        src="https://bergamobrescia2023.it/images/Brembo_logo.svg"
                                        decoding="async" data-nimg="intrinsic"
                                        style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                        loading="lazy"/&gt;
                                      </noscript>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ height: "100%" }}
                              className="ant-col ant-col-lg-1 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div
                                className="ant-divider ant-divider-vertical"
                                style={{ minHeight: "94px" }}
                                role="separator"
                              />
                            </div>
                            <div className="ant-col ant-col-lg-6 ant-col-xl-6 ant-col-xxl-6">
                              <div
                                className="ant-row"
                                style={{ rowGap: "23px" }}
                              >
                                <div style={{}} className="ant-col ant-col-24">
                                  <span
                                    className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzrsu"
                                    mt={20}
                                  >
                                    PARTNER DI AREA
                                  </span>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <div
                                    className="ant-row"
                                    style={{
                                      marginLeft: "-15px",
                                      marginRight: "-15px",
                                      rowGap: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.fsitaliane.it"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2794%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="ferrove"
                                            src="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg 1x, https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="ferrove"
                                            srcSet="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/Ferrovie_stato_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.milanbergamoairport.it/en/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27110%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="Milan airport"
                                            src="https://bergamobrescia2023.it/images/BGY_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/BGY_logo.svg 1x, https://bergamobrescia2023.it/images/BGY_logo.svg 2x"
                                          />
                                          <noscript>
                                            &lt;img alt="Milan airport"
                                            srcSet="https://bergamobrescia2023.it/images/BGY_logo.svg
                                            1x,
                                            https://bergamobrescia2023.it/images/BGY_logo.svg
                                            2x"
                                            src="https://bergamobrescia2023.it/images/BGY_logo.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                                            loading="lazy"/&gt;
                                          </noscript>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ height: "100%" }}
                              className="ant-col ant-col-lg-1 ant-col-xl-1 ant-col-xxl-1"
                            >
                              <div
                                className="ant-divider ant-divider-vertical"
                                style={{ minHeight: "94px" }}
                                role="separator"
                              />
                            </div>
                            <div className="ant-col ant-col-lg-3 ant-col-xl-3 ant-col-xxl-3">
                              <div
                                className="ant-row"
                                style={{ rowGap: "23px" }}
                              >
                                <div style={{}} className="ant-col ant-col-24">
                                  <span
                                    className="ant-typography styled__StyledTitle-sc-1ih4wg3-0 ePzrsu"
                                    mt={20}
                                  >
                                    MEDIA PARTNER
                                  </span>
                                </div>
                                <div style={{}} className="ant-col ant-col-24">
                                  <div
                                    className="ant-row"
                                    style={{
                                      marginLeft: "-15px",
                                      marginRight: "-15px",
                                      rowGap: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                      }}
                                      className="ant-col"
                                    >
                                      <a
                                        href="https://www.gruppo24ore.ilsole24ore.com/it-it/"
                                        target="_blank"
                                      >
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span
                                            style={{
                                              boxSizing: "border-box",
                                              display: "block",
                                              width: "initial",
                                              height: "initial",
                                              background: "none",
                                              opacity: 1,
                                              border: 0,
                                              margin: 0,
                                              padding: 0,
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                              }}
                                              alt=""
                                              aria-hidden="true"
                                              src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2794%27%20height=%2732%27/%3e"
                                            />
                                          </span>
                                          <img
                                            alt="ferrove"
                                            src="https://bergamobrescia2023.it/images/gruppo-24.svg"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              bottom: 0,
                                              right: 0,
                                              boxSizing: "border-box",
                                              padding: 0,
                                              border: "none",
                                              margin: "auto",
                                              display: "block",
                                              width: 0,
                                              height: 0,
                                              minWidth: "100%",
                                              maxWidth: "100%",
                                              minHeight: "100%",
                                              maxHeight: "100%",
                                            }}
                                            srcSet="https://bergamobrescia2023.it/images/gruppo-24.svg 1x, https://bergamobrescia2023.it/images/gruppo-24.svg 2x"
                                          />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  )
}

export default BergamoBresciaFooter
