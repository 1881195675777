/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Grid } from "@theme-ui/components"
import { i18nContext, languages } from "../../context/i18nContext"
import { LanguageSwitcherContext } from "../../context/languageSwitcherContext"
import { PageSectionContext } from "../../context/pageSectionContext"
import Hreflang from "../home/hreflang"
import Canonical from "../home/canonical"

const Layout = ({ children, locale, i18nPaths, theme, pageSections }) => {
  const data = useStaticQuery(graphql`
    query CompassiSiteQuery {
      datoCmsSite: datoCmsSite {
        locale
      }
      gatsbySite: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <PageSectionContext.Provider value={pageSections}>
      <i18nContext.Provider
        value={languages[locale] || languages[data.datoCmsSite.locale]}
      >
        <LanguageSwitcherContext.Provider
          value={{ activeLocale: locale, paths: i18nPaths || [] }}
        >
          <Hreflang
            paths={i18nPaths}
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
          />
          <Canonical
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
            paths={i18nPaths}
          />
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box as="main">{children}</Box>
            </Box>
          </Box>
        </LanguageSwitcherContext.Provider>
      </i18nContext.Provider>
    </PageSectionContext.Provider>
  )
}

export default Layout
