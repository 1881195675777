import React from "react"
import { Box, Heading } from "theme-ui"

const TitleAndDescription = ({ block }) => {
  const { title, description, darkBackground } = block

  return (
    <Box sx={{ backgroundColor: darkBackground ? "compassi" : "light" }}>
      <Box sx={{ p: [3, 4, 7], pt:[6,6,7] }}>
        <Box>
          <Heading
            as="h2"
            sx={{
              fontSize: [5, 6],
              fontWeight: "600",
              mb: [0],
              color: darkBackground ? "light" : "compassi",
              fontFamily:"compassi",
            }}
          >
            {title}
          </Heading>
          <Box sx={{
            p:{
                fontFamily:"body",
                fontSize:[2,6],
                color: darkBackground ? "light" : "dark",
                py:[3,4]
            }
          }} dangerouslySetInnerHTML={{ __html : description}} />
        </Box>
      </Box>
    </Box>
  )
}

export default TitleAndDescription
