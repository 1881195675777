import React, { useState } from "react"
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

import { Box, Grid, Heading, Flex, Text, Image } from "theme-ui"
import ArrowLeft from "../../images/arrow-left.inline.svg"
import ArrowRight from "../../images/arrow-right.inline.svg"
import { useBreakpointIndex } from "@theme-ui/match-media"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])

const Carousel = ({ block }) => {
  const { title, winners } = block

  const breakpointIndex = useBreakpointIndex()
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)
  return (
    <Box sx={{ p: [3, 4, 7], pr: [0, 0, 0] }}>
      <Grid
        columns={["1fr", "1fr", ".5fr .75fr", ".25fr .75fr"]}
        sx={{
          height: "100%",
          zIndex: 0,
          ".swiper": {
            height: "100%",
          },
          ".swiper-button-disabled": {
            svg: {
              path: {
                fill: "#F0DEDE",
              },
            },
          },
        }}
      >
        <Flex
          sx={{
            height:"100%",
            flexDirection: ["row", "row", "column"],
            justifyContent: "space-between",
            position: "relative",
            alignItems:"flex-start"
          }}
        >
          <Box>
            <Heading as="h2" variant="h3" sx={{
              fontFamily:"compassi",
              fontWeight:"600",
              color:"compassi",
              fontSize:[3,4],
              maxWidth:["100%","240px"],
              my:[3,0]
            }} 
              dangerouslySetInnerHTML={{ __html : title.replace("Compassi d’Oro","<em>Compassi d’Oro</em>")}}
            />
          </Box>
          <Flex sx={{ display: ["none","flex"], width:"100%", alignItems:"flex-start", minWidth: "94px" }}>
            <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: ["-2px"] }}>
              <Flex
                sx={{
                  p: [2],
                  border: "2px solid",
                  borderColor: "light",
                  cursor: "pointer",
                  alignItems: "flex-start",
                  alignContent: "center",
                  minWidth: "48px",
                  svg: {
                    width: "auto",
                    height: "54px",
                    fill: "compassi",
                    "*": {
                      fill: "compassi",
                    },
                  },
                }}
              >
                <ArrowLeft />
              </Flex>
            </Box>
            <Box as="div" className="nextEl" ref={node => setNextEl(node)}>
              <Flex
                sx={{
                  p: [2],
                  border: "2px solid",
                  borderColor: "light",
                  cursor: "pointer",
                  alignItems: "flex-start",
                  alignContent: "center",
                  svg: {
                    width: "auto",
                    height: "54px",
                    fill: "compassi",
                    "*": {
                      fill: "compassi",
                    },
                  },
                }}
              >
                <ArrowRight />
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Box sx={{
          ".swiper-slide" :{
            height:"auto" 
          }
        }}>
          {console.log(breakpointIndex)}
          <Swiper
            autoHeight={false}
            spaceBetween={15}
            slidesPerView={3}
            grabCursor={true}
            draggable={true}
            breakpoints={{
              320: {
                slidesPerGroup: 1,
                slidesPerView: 1.4,
              },
              640: {
                slidesPerGroup: 1,
                slidesPerView: 1.4,
              },
              896: {
                slidesPerGroup: 2,
                slidesPerView: 2,
              },
              1366: {
                slidesPerGroup: 3,
                slidesPerView: 3,
              },
            }}
            navigation={{ prevEl, nextEl }}
          >
            {winners.map(winner => (
              <SwiperSlide key={winner.originalId}>
                <WinnerComponent winner={winner} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Grid>
    </Box>
  )
}

const WinnerComponent = ({ winner }) => {
  const { artist, description, image, title } = winner
  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      sx={{
        height:"100%",
        position: "relative",
      }}
      onMouseEnter={e => setIsHover(!isHover)}
      onMouseLeave={e => setIsHover(!isHover)}
    >
      <Image src={image.url} alt={image.alt} />
      <Box
        className={isHover ? "hover" : ""}
        sx={{
          position: ["relative","absolute"],
          top: 0,
          left: 0,
          width:"100%",
          height: "100%",
          p: [3,5],
          backgroundColor: "compassi",
          color: "light",
          opacity: [1,0],
          transition: "opacity .3s ease-in-out",
          "&.hover": {
            opacity: 1,
            transition: "opacity .3s ease-in-out",
          },
        }}
      >
        <Text
          sx={{
            fontFamily: "body",
            fontSize: [4, 5],
            lineHeight: "30px",
          }}
        >
          {title}
        </Text>
        <Box
          sx={{
            width: "30px",
            borderBottom: "1px solid",
            height: "16px",
            color: "light",
            my: [2, 2],
          }}
        ></Box>
        <Text
          as="div"
          dangerouslySetInnerHTML={{ __html: description }}
          sx={{
            p: {
              fontFamily: "compassi",
              fontSize: [4, 5],
              lineHeight: "30px",
            },
          }}
        />
        <Text
          sx={{
            fontFamily: "compassi",
            fontSize: [4, 5],
            fontWeight: "400",
            lineHeight: "30px",
          }}
        >
          {artist}
        </Text>
      </Box>
    </Box>
  )
}

export default Carousel
