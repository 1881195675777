import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Visible } from "react-grid-system"
import Layout from "../components/compassi/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getCustomPage } from "../utils/path"
import { Box, Container } from "theme-ui"
import BergamoBresciaFooter from "../components/compassi/bergamoBresciaFooter"
import Carousel from "../components/compassi/carousel"
import EventsCarousel from "../components/compassi/eventsCarousel"
import Hero from "../components/compassi/hero"
import Marquee from "../components/compassi/marquee"
import PodcastCollection from "../components/compassi/podcastCollection"
import Sponsors from "../components/compassi/sponsors"
import Stories from "../components/compassi/stories"
import TitleAndDescription from "../components/compassi/titleAndDescription"
import TitleAndTable from "../components/compassi/titleAndTable"
import Footer from "../components/home/blocks/footer"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const Page = ({ data: { page, site } }) => {
  let tmpi18nPaths = []
  site.locales.map(locale => {
    tmpi18nPaths.push({
      locale: locale,
      value: getCustomPage(page, locale),
    })
  })

  const i18nPaths = tmpi18nPaths
  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} theme="dark">
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      {page.content &&
        page.content.map(block => (
          <Box
            as="section"
            key={block.id}
            id={block.id}
            sx={{
              position: "relative",
              zIndex: 99,
              "*::selection": {
                
              },
            }}
          >
            {console.log("block.darkBackground",block.darkBackground)}
            {block.model.apiKey === "footer2023" && <Box sx={{ px: [3, 4, 7],py:[4]}}><Footer block={block} /></Box>}
            {block.model.apiKey === "title_and_description_compassi" && (
              <TitleAndDescription block={block} />
            )}
            {block.model.apiKey === "title_and_table_compassi" && (
              <TitleAndTable block={block} />
            )}
            {block.model.apiKey === "bergamobrescia_footer" && (
              <BergamoBresciaFooter block={block} />
            )}
            {block.model.apiKey === "carousel_compassi" && (
              <Carousel block={block} />
            )}
            {block.model.apiKey === "events_carousel_compassi" && (
              <EventsCarousel block={block} />
            )}
            {block.model.apiKey === "compassi_hero" && <Hero block={block} />}
            {block.model.apiKey === "marquee_compassi" && (
              <Marquee block={block} />
            )}
            {block.model.apiKey === "podcast_collection_compassi" && (
              <PodcastCollection block={block} />
            )}
            {block.model.apiKey === "sponsors_compassi" && (
              <Sponsors block={block} />
            )}
            {block.model.apiKey === "stories_compassi" && (
              <Stories block={block} />
            )}
          </Box>
        ))}
    </Layout>
  )
}

export default Page

const Wrapper = styled.div`
  padding-top: 100px;
`

export const query = graphql`
  query CompassiQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsCompassi(id: { eq: $id }) {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsFooter2023 {
          id
          privacy {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          cookie {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          credits {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          legalInfo
          model {
            apiKey
          }
        }
        ... on DatoCmsBergamobresciaFooter {
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsCarouselCompassi {
          id
          title
          winners {
            ... on DatoCmsWinnersCompassi {
              id: originalId
              title
              artist
              description
              image {
                url
                alt
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventsCarouselCompassi {
          id
          events {
            ... on DatoCmsEventCompassi {
              title
              image {
                url
                alt
              }
              description
              link {
                ...InternalLinkDetails
                ...ExternalLinkDetails
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndDescriptionCompassi {
          title
          description
          darkBackground
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndTableCompassi {
          title
          table
          model {
            apiKey
          }
        }
        ... on DatoCmsCompassiHero {
          id
          title
          subtitle
          heroImage {
            url
            alt
          }
          content {
            ... on DatoCmsTitleAndDescriptionCompassi {
              title
              description
              darkBackground
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsMarqueeCompassi {
          id
          text
          image {
            url
            alt
          }

          link {
            ...InternalLinkDetails
            ...ExternalLinkDetails
          }
          darkBackground
          model {
            apiKey
          }
        }
        ... on DatoCmsPodcastCollectionCompassi {
          id
          podcasts {
            ... on DatoCmsPodcastCompassi {
              title
              description
              link {
                ...ExternalLinkDetails
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSponsorsCompassi {
          id
          content {
            ... on DatoCmsLogoBlock {
              section
              partners {
                id
                name
                text
                whiteLogo{
                  url
                  alt
                }
                url
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesCompassi {
          id
          title1
          title2
          title3
          gallery {
            url
            alt
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
