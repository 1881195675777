import React from "react"
import { Box, Container, Flex, Grid, Image, Text } from "theme-ui"
import { MagicLink } from "../../utils/magicLink"
import { OutboundLink } from "../home/link"

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

const Sponsors = ({ block }) => {
  const logoBlocks = block.content
  const chunks = logoBlocks

  return (
    logoBlocks.length > 0 && (
      <Box sx={{ backgroundColor: "compassi" }}>
        <Box sx={{ p: [3, 4, 7], maxWidth:"1700px", m: ["0 auto"] }}>
            <Grid
              columns={[`repeat(6,1fr)`]}
              gap={[4]}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: ["flex-start", "space-around"],
              }}
            >
              {logoBlocks.map((block, index) => (
                <Box sx={{
                  mr: "auto"
                }}>
                  <TextComponent block={block} index={index} />
                </Box>
              ))}
            </Grid>
        </Box>
      </Box>
    )
  )
}

const TextComponent = ({ block, index }) => {
  //   const getGridStructure = index => {
  //     switch (index) {
  //       case 0:
  //         return ["1", "1/2", "1/2", "1/3"]
  //         break
  //       case 1:
  //         return ["1", "2/2", "2/2", "4/4"]
  //         break
  //       case 2:
  //         return ["1", "1/2", "1/2", "5/6"]
  //         break
  //       case 3:
  //         return ["1", "2/2", "2/2", "7/8"]
  //         break
  //       case 4:
  //         return ["1", "1/2", "1/2", "2/3"]
  //         break
  //       case 5:
  //         return ["1", "2/2", "2/2", "3/5"]
  //         break
  //       case 6:
  //         return ["1", "1/2", "1/2", "1/2"]
  //         break
  //       case 7:
  //         return ["1", "2/2", "2/2", "2/4"]
  //         break
  //       case 8:
  //         return ["1", "1/2", "1/2", "4/5"]
  //         break
  //     }
  //   }

  const { section, partners } = block
  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderColor: "light",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        pt: [1],
      }}
    >
      <Text
        sx={{
          textTransform: "uppercase",
          fontFamily: "compassi",
          color: "light",
          fontSize: [0],
        }}
      >
        {section}
      </Text>
      <Flex sx={{}}>
        {console.log("partners", partners)}
        {partners.map((partner, index) => (
          <OutboundLink
            href={partner.url || ""}
            sx={{
              mt: [2],
              display: "flex",
            }}
          >
            {partner.whiteLogo && partner.whiteLogo.url ? (
              <Image
                sx={{
                  width: "100%",
                  objectFit: "contain",
                  height: "auto",
                  maxHeight: "65px",
                  mr: partners.length > 1 && [5],
                }}
                src={partner.whiteLogo.url}
                alt={partner.whiteLogo.alt}
              />
            ) : (
              <Text
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "compassi",
                  color: "light",
                  fontSize: [0],
                  fontFamily: "heading",
                }}
              >
                {partner.name}
              </Text>
            )}
          </OutboundLink>
        ))}
      </Flex>
    </Box>
  )
}

export default Sponsors
