import React, { useState } from "react"
import { Box, Container, Heading, Grid, Flex, Image, Text } from "theme-ui"
import RichContentStructuredText from "../editions/2020/richContentBlockText"
import { InboundLink } from "../home/link"
import { getPagePath } from "../../utils/path"
import ArrowLongRight from "../../images/arrow-right.inline.svg"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"
import { MagicLink } from "../../utils/magicLink"

const colors = [
  { backgroundColor: "#F2EEE3", text: "#81713B" },
  { backgroundColor: "#E7E7E7", text: "#333331" },
  { backgroundColor: "#F0E6DE", text: "#835935" },
  { backgroundColor: "#F1E1E1", text: "#AF2B37" },
]

const EventsCollection = ({ block }) => {
  const mediaIndex = useBreakpointIndex()
  console.log("block EventsCollection", block)
  const { title, events } = block
  const [currentPastEvents, setCurrentPastEvents] = useState(events)

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Box
      sx={{
        p: [3, 4, 7],
        margin: "0 auto",
      }}
    >
      <Heading
        as="div"
        sx={{
          mb: [3, 4],
          h2: {
            fontSize: [7, 8, 9, 11], //"4.5rem",
            fontFamily: "compassi",
            lineHeight: "heading",
            fontWeight: "heading",
            color: "text",
            mb: [5, 7],
            mt: 4,
            fontWeight: "400",
            em: {
              "-webkit-text-stroke": "1px #181818",
              color: "transparent",
              fontStyle: "normal",
              fontFamily: "body",
            },
          },
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Box sx={{ position: "relative", width: "100%" }}>
        {mediaIndex > 1 ? (
          <>
            <Grid
              columns={["1fr", "1fr", ".47fr .53fr", ".47fr .53fr"]}
              gap={[0]}
              sx={{
                opacity: "0",
                backgroundColor: colors[0].backgroundColor,
              }}
            >
              <Box
                sx={{
                  img: {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    minHeight: ["369px", "600px"],
                  },
                }}
              >
                <Image src={events[0].image.url} alt={events[0].image.alt} />
              </Box>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexDirection: ["column", "column", "row", "row"],
                }}
              >
                <Box sx={{ p: [3] }}>
                  <Text
                    as="h2"
                    variant="h4"
                    sx={{
                      display: ["block", "block", "none", "none"],
                      my: [0, 0],
                      color: colors[0].text,
                      fontSize: [4, 5],
                      fontWeight: "bold",
                    }}
                  >
                    {events[0].title}
                  </Text>
                  <Box
                    sx={{
                      mb: [4, 6],
                      
                      p: {
                        color: colors[0].text,
                        fontSize: [4, 8],
                        lineHeight: "1",
                      },
                    }}
                  >
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: events[0].description,
                      }}
                    />
                  </Box>
                  {events[0].link && (
                    <MagicLink
                      item={events[0].link}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        svg: {
                          fill: "light",
                          "#Fill-3": {
                            fill: "light",
                          },
                          stroke: "light",
                        },
                      }}
                      variant="links.simple"
                    >
                      {events[0].link.anchor}
                    </MagicLink>
                  )}
                </Box>
                <Box
                  sx={{
                    display: ["none", "none", "flex", "flex"],
                    maxWidth: "150px",
                    p: [2],
                    pb: [4],
                    pt: [3],
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        color: colors[0].text,
                        my: [0, 0],
                        fontWeight: "bold",
                      }}
                    >
                      01
                    </Text>
                  </Box>
                  <Text
                    as="h2"
                    variant="h4"
                    sx={{
                      my: [0, 0],
                      color: colors[0].text,
                      transform: "rotate(180deg)",
                      fontSize: [4, 5],
                      writingMode: "vertical-rl",
                      textOrientation: "mixed",
                    }}
                  >
                    {events[0].title}
                  </Text>
                </Box>
                <Box
                  sx={{
                    display: ["block", "block", "none", "none"],
                    p: [3],
                    textAlign: "right",
                  }}
                >
                  <Text
                    as="h2"
                    variant="h4"
                    sx={{
                      color: colors[0].text,
                      my: [0, 0],
                      fontWeight: "bold",
                    }}
                  >
                    01
                  </Text>
                </Box>
              </Flex>
            </Grid>

            <Box
              sx={{
                display: "block",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                right: 0,
                overflow: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {currentPastEvents &&
                currentPastEvents.map((event, index) => (
                  <Box
                    sx={{
                      backgroundColor: colors[index].backgroundColor,
                      position: "relative",
                      display: "block",
                      float: "right",
                      width: "80px",
                      maxWidth: "80px",
                      overflow: "hidden",
                      "&.active": {
                        maxWidth: "100%",
                        width: "100%",
                        height: "100%",
                        backgroundColor: colors[index].backgroundColor,
                        ".static": {
                          width: "100%",
                          position: "static",
                        },
                      },
                      WebkitTransition: "all 1s ease",
                      MozTransition: "all 1s ease",
                      msTransition: "all 1s ease",
                      OTransition: "all 1s ease",
                      transition: "all 1s ease",
                    }}
                    as="section"
                    id={"acc" + index}
                    className={activeIndex === index && "active"}
                  >
                    <Box
                      className="static"
                      sx={{
                        maxWidth: activeIndex !== index ? "58px" : "100%",
                        transition: "all .3s linear",
                        display: "flex",
                        minWidth: "58px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          display: ["none", "none", "flex", "flex"],
                          maxWidth: "80px",
                          minWidth: "80px",
                          p: [2],
                          pb: [4],
                          pt: [3],
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        onClick={() => setActiveIndex(index)}
                      >
                        <Box>
                          <Text
                            as="h2"
                            variant="h4"
                            sx={{
                              color: colors[index].text,
                              my: [0, 0],
                              fontSize: [6, 7],
                              fontWeight: "400",
                              fontFamily: "compassi",
                            }}
                          >
                            {index + 1 > 9 ? index + 1 : "0" + (index + 1)}
                          </Text>
                        </Box>
                        <Text
                          as="h2"
                          variant="h4"
                          sx={{
                            my: [0, 0],
                            color: colors[index].text,
                            transform: "rotate(180deg)",
                            fontSize: [6, 7],
                            fontFamily: "compassi",
                            writingMode: "vertical-rl",
                            textOrientation: "mixed",
                            fontWeight: "400",
                          }}
                        >
                          {event.title}
                        </Text>
                      </Box>
                      <Grid
                        className="contant-shown"
                        columns={["1fr", "1fr", "1fr 1fr"]}
                        sx={{
                          width: "100%",
                          opacity: activeIndex === index ? 1 : 0,
                          transition: "all .1s linear",
                        }}
                        gsp={[0]}
                      >
                        <Box
                          sx={{
                            img: {
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            },
                            ".gatsby-image-wrapper": {
                              height: "100%",
                              img: {
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              },
                            },
                          }}
                        >
                          <Image src={event.image.url} alt={event.image.alt} />
                        </Box>
                        <Box
                          sx={{
                            p: [3],
                            opacity: activeIndex === index ? 1 : 0,
                            transition: "all .1s linear .6s",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            {" "}
                            <Text
                              as="h2"
                              variant="h4"
                              sx={{
                                display: ["block", "block", "none", "none"],
                                my: [0, 0],
                                color: colors[index].text,
                                fontSize: [4, 5],
                                fontWeight: "bold",
                              }}
                            >
                              {event.title}
                            </Text>
                            <Box
                              sx={{
                                mb: [4, 6],
                                
                                p: {
                                  "&:first-child": {
                                    fontFamily: "compassi",
                                  },
                                  color: colors[index].text,
                                  fontSize: [4, 6],
                                  lineHeight: "1",
                                  fontFamily: "body",
                                  mb: [5, 6],
                                  lineHeight: "40px",
                                },
                              }}
                            >
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: event.description,
                                }}
                              />
                            </Box>
                          </Box>
                          {event.link && (
                            <MagicLink
                              item={event.link}
                              sx={{
                                fontSize: [5, 6],
                                textDecoration: "underline",
                                fontFamily: "compassi",
                                display: "flex",
                                alignItems: "center",
                                color: colors[index].text,
                                svg: {
                                  fill: "light",
                                  "#Fill-3": {
                                    fill: "light",
                                  },
                                  stroke: "light",
                                },
                              }}
                              variant="links.simple"
                            >
                              {event.link.anchor}
                            </MagicLink>
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                ))}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                backgroundColor: colors[activeIndex].backgroundColor,
                opacity: 1,
                transition: "all .3s linear",
                pb: [4],
              }}
            >
              <Box
                sx={{
                  img: {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    minHeight: ["369px", "600px"],
                  },
                }}
              >
                <Image
                  src={events[activeIndex].image.url}
                  alt={events[activeIndex].image.alt}
                />
              </Box>

              <Box
                sx={{
                  p: [3],
                  opacity: 1,
                  transition: "all .1s linear .6s",
                }}
              >
                <Text
                  as="h2"
                  variant="h4"
                  sx={{
                    display: ["block", "block", "none", "none"],
                    my: [0, 0],
                    color: colors[activeIndex].text,
                    fontSize: [4, 5],
                    fontWeight: "bold",
                  }}
                >
                  {events[activeIndex].title}
                </Text>
                <Box
                  sx={{
                    mb: [4, 6],
                    
                    p: {
                      color: colors[activeIndex].text,
                      fontSize: [4, 8],
                      lineHeight: "1",
                    },
                  }}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: events[activeIndex].description,
                    }}
                  />
                </Box>
                {events[activeIndex].link && (
                  <MagicLink
                    item={events[activeIndex]}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      svg: {
                        fill: "light",
                        "#Fill-3": {
                          fill: "light",
                        },
                        stroke: "light",
                      },
                    }}
                    variant="links.simple"
                  >
                    {events[activeIndex].link.anchor}
                  </MagicLink>
                )}
              </Box>
            </Box>
            <Grid
              columns={[`repeat(${events.length},80px)`]}
              gap={[0]}
              sx={{
                height: "80px",
                width: "100%",
              }}
            >
              {currentPastEvents &&
                currentPastEvents.map((event, index) => (
                  <Box
                    sx={{
                      backgroundColor: colors[index].backgroundColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <Text
                      as="h2"
                      variant="h4"
                      sx={{
                        textAlign: "center",
                        color: colors[index].text,
                        my: [0, 0],
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1 > 9 ? index + 1 : "0" + (index + 1)}
                    </Text>
                  </Box>
                ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default EventsCollection
