import React from "react"
import { Box, Flex, Heading } from "theme-ui"

const TitleAndTable = ({ block }) => {
  const { title, table, darkBackground } = block

  return (
    <Box sx={{ backgroundColor: darkBackground ? "compassi" : "light" }}>
      <Box sx={{ p: [3, 4, 7], pt:[3,4,8] }}>
        <Flex sx={{
          justifyContent:"space-between",
          flexWrap:"wrap"
          
        }}>
          <Heading
            as="h2"
            sx={{
              mr:[3],
              fontSize:[3,4],
              fontWeight: "600",
              mb: [0],
              color: darkBackground ? "light" : "compassi",
              fontFamily: "compassi",
              maxWidth:"240px"
            }}
          >
            {title}
          </Heading>
          <Box
            sx={{
              width:"780.02px",
              p: {
                fontFamily: "body",
                fontSize: [2, 6],
                color: darkBackground ? "light" : "dark",
              },
              table:{
                width:"100%",
                border:"none",
                "td, th":{
                  fontSize:[2,5],
                  border:"none",
                  borderBottom:"1px solid",
                  "strong":{
                    pt:[4],
                  }
                }
              }
            }}
            dangerouslySetInnerHTML={{ __html: table }}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default TitleAndTable
