import React from "react"
import { Box, Container, Flex, Heading, Image, Text } from "theme-ui"
import { Link } from "gatsby"
import { InboundLink, OutboundLink } from "../home/link"
import { m } from "framer-motion"
import TitleAndDescription from "./titleAndDescription"
import { useBreakpointIndex } from "@theme-ui/match-media"

const Hero = ({ block }) => {
  const mediaIndex = useBreakpointIndex()
  const { title, subtitle, heroImage, content } = block

  return (
    <Box sx={{ backgroundColor: "compassi" }}>
      <Box sx={{ p: [3, 4, 7], pb:[0,0,0,0] }}>
        <Box
          sx={{
            pb: [3, 6],
            a: {
              color: "light",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color:"light",
                textDecoration: "underline",
              },
            },
          }}
        >
          <Link to="/" sx={{}}>
            ⟵ dimoredesign
          </Link>
        </Box>
        <Box sx={{ pb: [4, 7] }}>
          <Heading
            as="h1"
            sx={{
              fontFamily: "compassi",
              fontSize: [8, 11],
              lineHeight: ["60px","120px"],
              fontWeight: "600",
              color: "light",
              maxWidth: "846px",
            }}
          >
            
            {title}
          </Heading>
        </Box>
        <Flex sx={{ width: "100%", height: ["auto","105vh"], flexDirection:["column","row"], position: "relative" }}>
          <Box>
            <Heading
              as="h2"
              sx={{
                p : {
                  color: "light",
                  maxWidth: "425px",
                  fontSize: [6, 9],
                  lineHeight: "60px",
                  fontFamily: "compassi",
                }
              }}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Box>
          <Box
            sx={{
              width: "90%",
              height: "100%",
              img: {
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
              position: ["relative","absolute"],
              left: [0,"50%"],
              transform: [0,"translateX(-50%)"],
              top: [0],
            }}
          >
            <Image src={heroImage.url} alt={title} />
          </Box>
        </Flex>
      </Box>
      <Box>
        {content.map(block => (
          <TitleAndDescription block={block} />
        ))}
      </Box>
    </Box>
  )
}

export default Hero
